import Vue from "vue";
import "./axios";

//  Echars
import * as echarts from "echarts";

import VueCropper from 'vue-cropper'
Vue.use(VueCropper)

// ElementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI)

// xss 防攻击
import VueXss from 'vue-xss'
Vue.use(VueXss)

import { fabric } from 'fabric'
Vue.use(fabric);

// 赵金福 - 私人的方法
import sFunction from '../utils/requireFunction'
Vue.prototype.zF = sFunction

// moment - 日期
import "../utils/filters";

// 滑动验证
import SlideVerify from 'vue-monoplasty-slide-verify';
Vue.use(SlideVerify);

// 打印
import Print  from 'vue-print-nb';
Vue.use(Print);

// 拖拽·缩放·画布插件
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
Vue.component('vue-draggable-resizable', VueDraggableResizable)
import {
  checkSessionsObj,
  setDictionary,
  gettyped,
  validatePhone,
  validateIdCard,
  validateIdCards,
  validateTime,
  refresh,
  needLogin,
  beforeAvatarUpload,
  requestMine,
  handleBigImg,
  uploadFetch,
  exceedd,
  hideIdCard,
  toPath,
  toTree,
  connectWebsocket,
  judgeServerHostname,
  setCookie,
  getCookie,
  delCookie,
  delAllStorage2
} from "../utils/common";
// 判断域名
Vue.prototype.$judgeServerHostname=judgeServerHostname;

// Websocket
Vue.prototype.$lWebsocket = connectWebsocket;

// 刷新
Vue.prototype.$refresh = refresh;
Vue.prototype.$needLogin = needLogin;


// 表单
Vue.prototype.$checkSessionsObj = checkSessionsObj;
Vue.prototype.$setDictionary = setDictionary;
Vue.prototype.$gettyped = gettyped;
Vue.prototype.$validatePhone = validatePhone;
Vue.prototype.$validateIdCard = validateIdCard;
Vue.prototype.$validateIdCards = validateIdCards;
Vue.prototype.$validateTime = validateTime;

// upload img
Vue.prototype.$beforeAvatarUpload = beforeAvatarUpload;
Vue.prototype.$requestMine = requestMine;
Vue.prototype.$exceedd = exceedd;
Vue.prototype.$handleBigImg = handleBigImg;
Vue.prototype.$uploadFetch = uploadFetch;

Vue.prototype.$hideIdCard = hideIdCard;

Vue.prototype.$echarts = echarts;
Vue.prototype.$toPath = toPath;
Vue.prototype.$toTree = toTree; // 树

Vue.prototype.$getCookie = getCookie;
Vue.prototype.$setCookie = setCookie;
Vue.prototype.$delCookie = delCookie;
Vue.prototype.$delAllStorage2 = delAllStorage2;
